<template>
  <div>
    <list-template
        :loading="loading"
        :total="total"
        :current-page="page"
        :table-data="tableData"
        :table-config="tableConfig"
        @onChangePage="changeCurrentPage"
        ref="table"
        has-add
        @onAdd="$router.push('./add')"
        @onHandle="tableHandle"
    >
    </list-template>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》 ';
import {mapGetters, mapMutations, mapState} from "vuex";
import tools from "@/utils/tools";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  computed:{
    ...mapState(["page"]),
    ...mapGetters(["userInfo"])
  },
  props: {},
  data() {
    // 这里存放数据
    return {
      loading:true,
      // 数据总量
      total:0,
      tableConfig:[
        {
          prop:"name",
          label:"运营位名称",
        },
        {
          prop:"content_type_text",
          label:"投放内容类型",
        },
        {
          prop:"start_at",
          label:"开始时间"
        },
        {
          prop:"end_at",
          label:"结束时间"
        },
        {
          prop:"status_text",
          label:"当前状态",
        },
        {
          prop:"creator",
          label:"创建人",
        },
        {
          label:"操作",
          handle:true,
          width:180,
          buttons:(row)=> {
            const {status,content_type,is_reply} = row;
            switch (status){
              case 0:
                return  [{ type:"offShelf", text:"下架" }, { type:"view", text:"查看" }]
              case 1:
                return [{ type:"onShelf", text:"上架" }, { type:"view", text:"查看" }]
              case 2:
                if (content_type === 3){
                  if (is_reply === 1)
                    return [{ type:"view", text:"查看" }, { type:"export", text:"导出" }]
                  else return [{ type:"view", text:"查看" }]
                }else return [{ type:"view", text:"查看" }]

            }
          }
        },
      ],
      tableData:[]
    }
  },
  // 监控 data 中的数据变化
  watch: {},
  // 方法集合
  methods: {
    ...mapMutations(["setPage"]),
    changeCurrentPage(e){
      this.setPage(e)
      this.getData();
    },
    // 获取列表基础数据
    getData() {
      this.loading = true;
      this.$_axios2.get("/api/operation/index",{
        params:{
          page:this.page
        }
      }).then(res=>{
        let {list,page} = res.data.data;
        this.tableData = list;
        this.total = page.total;

      }).finally(()=>this.loading = false);
    },
    // 表格后面操作被点击
    tableHandle(row, handle) {
      console.log(handle);
      const { type } = handle
      let changeStatus = (status)=>{
        let s = ["上架","下架"];
        this.$_axios2.get("/api/operation/index/status",{
          params:{
            id:row.id,
            status
          }
        }).then(res=>{
          let state = res.data.status;
          if (state === 0){
            this.$message.success(s[status]+"成功");
            row.status = status;
            row.status_text = status === 0?"上架":"下架";
            this.$forceUpdate();
          }else if(state === 1)
            this.$message.warning(s[status]+"失败");
          else if(state === 1001){
            this.$alert(res.data.msg);
          }else{
            this.$message.info(res.data.msg)
          }
        })
      }

      if(type === "onShelf") {
        this.$confirm("确认上架？").then(res=>{
          changeStatus(0)
        })
      } else if(type === "offShelf") {
        this.$confirm("确认下架？").then(res=>{
          changeStatus(1)
        })
      } else if(type === "view") {
        this.$router.push({path:"./look",query:{id:row.id}})
      } else if(type === "export") {
        this.$_axios2.get("/api/operation/index/download?id="+row.id).then(res=>{
          tools.download(row.name,res.data)
        })
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.$store.commit("setPage",1);
    this.getData();
  },
  beforeCreate() {
  },
  beforeMount() {
  }, // 生命周期 - 挂载之前
  beforeUpdate() {
  }, // 生命周期 - 更新之前
  updated() {
  }, // 生命周期 - 更新之后
  beforeDestroy() {
  }, // 生命周期 - 销毁之前
  destroyed() {
  }, // 生命周期 - 销毁完成
  activated() {
    this.getData();
  } // 如果页面有 keep-alive 缓存功能,这个函数会触发
}
</script>
<style scoped>
</style>
